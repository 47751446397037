<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-white">
    <body class="h-full">
    ```
-->
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
  >
    <!-- <div class="sm:mx-auto sm:w-full sm:max-w-sm">
       
        </div> -->
    <Signin
      auth-mode="signup"
      :force-login="true"
      from-action="loginPage"
      @close="handleAfterSignin"
    />
  </div>
</template>

<script setup>
definePageMeta({
  layout: false,
});
useHead({
  title: 'Login - MyProviders',
});

const route = useRoute();

const handleAfterSignin = async () => {
  const user = useUser();
  if (user.value) {
    const firebaseUser = useFirebaseUser();
    // force token refresh
    await firebaseUser.value.getIdToken(true);

    if (route.query.redirect) {
      await navigateTo(route.query.redirect, { external: true });
    } else {
      await navigateTo('/', { external: true });
    }
  }
};
</script>
